<template>
  <div class="contents">
    <div class="card shadow">
      <div class="card-body">
        <div class="btnwrap-left">
          <button type="button" @click="openAuthRegistPopup" class="btn btn-primary pr20 pl20">인증정보 추가</button>
        </div>
        <div class="btnwrap-right">
          <button type="button" @click="deleteAuth(checkedList)" class="btn btn-black pr20 pl20">일괄 삭제</button>
        </div>
        <table class="table01 ">
          <caption>카드단말기 인증정보 표</caption>
          <colgroup>
            <col v-for="(item, index) in tableHead" :key="index" :style="item.style"/>
          </colgroup>
          <thead>
            <th v-for="(item, index) in tableHead" :key="index" class="tx_cnt">{{item.col}}</th>
          </thead>
          <tbody>
            <tr v-for="(item, index) in tableBody" :key="index" class="">
              <td class="tx_cnt">
                <label><input type="checkbox" :value="item.idx" v-model="checkedList"></label>
              </td>
              <td class="tx_cnt">{{item.scrapSiteName}}</td>
              <td class="tx_cnt">{{item.authId}}</td>
              <td class="tx_cnt">{{item.regDt.substring(0,10)}}</td>
              <td class="tx_cnt">{{item.status == 'VALID' ? '정상' : '비정상'}}</td>
            </tr>
          </tbody>
        </table>
        <base-pagination
            :prefix="'/auth/pos'"
            :pTotalListItemCount="authCount"
            :pageInit="pageInit"
            @toParent:changePage="(val) => pageIndex = val"
        />
      </div>
    </div>
    <RegistAuth ref="authRegist" @callbackFnc="getAuthList"/>
  </div>
</template>
<script>
import registAuth from '@/components/pos/registAuth.vue'
export default {
  name: "pageAuthPos",
  components:{
    RegistAuth: registAuth
  },
  data: () => {
    return {
      prefix: "api/auth/pos",

      //checkbox_attrs
      checkedList: [],
      allSelected: false,

      tableHead:[
        {col: '선택', style:'width:50px;'},
        {col: '카드단말기', style:'width:200px;'},
        {col: 'ID', style:''},
        {col: '등록일자', style:'width:200px;'},
        {col: '상태', style:'width:100px;'},
      ],
      tableBody:[],

      //pagination
      authCount: 0,
      pageIndex: 10,
      pageLimit: 10,
      pageInit: false,
    }
  },
  watch(){
  },
  mounted(){
    this.getAuthList();
  },
  methods:{
    openAuthRegistPopup(){
      // 인증정보 추가 팝업 열기
      this.$refs.authRegist.openPopup();
    },
    // 등록된 인증정보 조회
    getAuthList(){
      let param = {
        limit: this.pageLimit,
        offset: this.pageIndex - this.pageLimit
      }
      this.$cAxios.post("api/auth/pos/auth/inquriy", param,{
        headers:{
          'Content-type':'application/json;'
        }
      }).then((res) => {
        if(null != res.data){
          this.tableBody = res.data;
        }
      });
    },
    deleteAuth(){
      if(this.checkedList.length < 1) {
        alert("삭제할 인증정보를 선택해주세요.");
        return false;
      }
      if(!confirm("삭제하시겠습니까?")) return false;

      let paramList = new Array();
      for(var i = 0 ; i< this.checkedList.length; i++){
        let param = {};
        param.idx = this.checkedList[i];
        paramList.push(param);
      }

      this.$cAxios.delete('api/auth/pos/remove',{data:paramList})
          .then((res) => {
            alert("삭제되었습니다.");
            this.getAuthList();
        });
    },
  },
}

</script>
