<template>
  <div class="w3-modal w3-animate-opacity" v-if="visible" style="display:block">
    <div class="w3-modal-content w3-card-4 w3-animate-zoom pt20 pb30 pr20 pl25" :style="{maxWidth:'500px'}">
      <div class="w3-center">
        <span @click="closePopup" class="w3-button w3-xlarge w3-transparent w3-display-topright fs30 bold lh25" title="Close Modal">×</span>
      </div>
      <validation-observer v-slot="{ handleSubmit }">
        <form class="form-group" action="">
          <h4 class="login-pop-h4 tx_cnt">인증정보 추가</h4>
          <div class="w3-section mr10 ml15">
            <table class="table02">
            <colgroup>
              <col style="width:200px"/>
              <col/>
            </colgroup>
            <tbody>
              <tr>
                <th class="tx_cnt bg-gray-100"><span>카드단말기</span></th>
                <td>
  <!-- TODO select 박스 채우기                  -->
                  <base-select-box
                      :items="selectBoxItems"
                      :selectBoxClass="selectBoxClass"
                      @toParent:selectValue="val => setSiteIdx(val)"/>
                </td>
              </tr>
              <tr>
                <th class="tx_cnt bg-gray-100"><span>웹 아이디</span></th>
                <td class="pt5 pb5">
                  <validation-provider rules="required" v-slot="{errors}">
                    <input class="form-control login-input" type="text"  v-model.trim="id" placeholder="아이디를 입력하세요" name="id" required />
                    <span v-if="errors[0]" class="txt-danger"><i class="fa fa-times-circle-o has-error" aria-hidden="true"></i> {{errors[0]}}</span>
                  </validation-provider>
                </td>
              </tr>
              <tr>
                <th class="tx_cnt bg-gray-100"><span>웹 패스워드</span></th>
                <td class="pt5 pb5">
                  <validation-provider rules="required" v-slot="{errors}">
                    <input class="form-control login-input" type="password"  v-model.trim="pwd" placeholder="비밀번호를 입력하세요" name="pwd" required />
                    <span v-if="errors[0]" class="txt-danger"><i class="fa fa-times-circle-o has-error" aria-hidden="true"></i> {{errors[0]}}</span>
                  </validation-provider>
                </td>
              </tr>
            </tbody>
            </table>
          </div>
          <div class="btnwrap clear pt20">
            <button @click="handleSubmit(submit())" type="button" class="btn btn-block btn-primary mr10 w115">확인</button>
            <button @click="closePopup" type="button" class="btn btn-block btn-default  w115">취소</button>
          </div>
        </form>
      </validation-observer>
    </div>
  </div>
</template>
<script>
export default{
  prefix : "/api/auth/pos",
  name: "PosRegistAuth",
  data: () => {
    return {
      visible : false,

      // input
      id:"",
      pwd:"",
      siteIdx:0,

      //
      selectBoxClass: 'form-control form-control-inline w90 mr5',
      selectBoxItems: [],
    }
  },
  methods:{
    openPopup(){
      this.visible = true;
    },
    closePopup(){
      this.id = "";
      this.pwd = "";
      this.visible = false;
    },
    // 카드단말기 정보 조회
    getTerminalList(){
      this.$cAxios.get("api/auth/pos/terminal/inquriy").then((res) => {
        if( null != res.data){
          this.selectBoxItems = [];
          this.selectBoxItems.push({
            key: '',
            value: "선택",
          });
          for(var i = 0; i < res.data.length; i++){
            this.selectBoxItems.push({
              key : res.data[i].idx,
              value : res.data[i].name
            });
          }
        }
      }).catch((err)=>{
        console.log(err.response);
      });
    },
    setSiteIdx(val){
      this.siteIdx = val;
    },
    // 인증정보 등록
    submit(){
      var regInfo = {
        authId : this.id,
        password : this.pwd,
        scrapSiteIdx: this.siteIdx
      }
      this.$cAxios.put("api/auth/pos/regist", JSON.stringify(regInfo),{
        headers:{
          'Content-Type':'application/json'
        }
      }).then((res) => {
        // 정상처리되었습니다.
        alert("인증정보가 추가되었습니다.");
        this.closePopup();
        this.$emit('callbackFnc');
      }).catch((err) => {
        // 오류 처리
        if (err.response) {
          // 서버 응답이 있는 경우
          console.log(err.response.data);
          console.log(err.response.status);
          console.log(err.response.headers);
          alert(err.response.data.message);
        } else if (err.request) {
          // 요청이 전송되었으나 응답이 없는 경우
          alert("서버로부터 응답이 없습니다. 네트워크 상태를 확인해주세요.");
        } else {
          // 기타 오류
          alert(`요청 중 오류가 발생했습니다: ${err.message}`);
        }
      });
    }
  },
  mounted() {
    this.getTerminalList();
  }

}
</script>